<template>
  <div class="rightSide">
    <div class="rightSide_inner">
      <div class="rightSide_inner_title">
        <p style="font-size: 30px; color: #333333">
          {{ $t("After-sales record list") }}
        </p>
      </div>

      <div class="button_style">
        <el-button
          style="background: #218da0; color: white"
          @click="handleAfterSalesList()"
        >
          {{ $t("Apply") }}
        </el-button>
        <el-button
          style="background: #218da0; color: white"
          @click="exportAfterOrder"
        >
          {{ $t("Export") }}
        </el-button>
      </div>

      <div class="table_style" v-for="(item, index) in listData" :key="index">
        <div class="table_style_content">
          <div class="table_style_content_first" style="position: relative">
            <input
              type="checkbox"
              style="margin-right: 10px"
              v-model="checkBox"
              :value="item.after_item"
            />
            <p style="font-size: 18px; color: #757575">
              <span v-if="item.status == 0">{{ $t("Pending review") }}</span>
              <span v-if="item.status == 1">{{ $t("Partially passed") }}</span>
              <span v-if="item.status == 2">{{ $t("All passed") }}</span>
              <span v-if="item.status == 3">{{ $t("Reject all") }}</span>
            </p>
            <p style="font-size: 15px; color: #218da0; margin-left: 10px">
              <!-- 退回次品 -->
              <span v-if="item.order_pack_type == 0"
                >{{ $t("Quality Problems")
                }}<!-- 质量问题 --></span
              >
              <span v-if="item.order_pack_type == 1"
                >{{ $t("Shipping Damage")
                }}<!-- 物流破损 --></span
              >
            </p>
            <el-button
              style="
                font-size: 15px;
                color: #218da0;
                position: absolute;
                top: -10px;
                right: 20px;
              "
              @click="handleAfterSaleOrderDetails(item)"
            >
              {{ $t("Details") }}
            </el-button>
            
            <el-button
              style="
                font-size: 15px;
                color: #889aa4;
                position: absolute;
                top: 30px;
                right: 20px;
              "
              v-if="item.status == 0"
              @click="deleteAfterOrder(item.after_item)"
            >
              撤销
            </el-button>
          </div>
          <div class="table_style_content_second">
            <p>
              {{ item.created_at | formatDateStr }}
            </p>
            <p style="margin-left: 20px">
              {{ $t("Quantity") }}：{{ item.quantity }}
            </p>
            <p style="margin-left: 20px">
              {{ $t("Amount") }}：¥{{ item.total }}
            </p>
            <p style="margin-left: 20px">
              {{ $t("Requisition Number") }}： {{ item.after_item }}
            </p>
          </div>
        </div>
      </div>

      <div class="footer">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.currentPage"
            :page-size="pages.pageSize"
            layout="total,  prev, pager, next, jumper"
            :total="pages.total"
            background
          >
          </el-pagination>
        </div>
    </div>
  </div>
</template>
<script>
import { GetAfterGoodsList } from "@/api/order/order.js";
import common from "@/common/mixins/common.js";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      listData: [],
      pages: {
        total: 100,
        currentPage: 1,
        pageSize: 20,
      },
      checkBox: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    //撤销售后
    deleteAfterOrder(item) {
      this.$axios.delete(`/w1/order/afterSales/deleter/${item}`).then((res) => {
        this.$message.success(this.$t("Operation complete"));
        this.getData();
      });
    },
    //导出售后订单
    exportAfterOrder() {
      if (this.checkBox.length > 0) {
        let lg = "";
        if (this.$i18n.locale == "zh-CN") {
          lg = "cn";
        } else {
          lg = "en";
        }
        let postData = {
          lg: lg,
          after_item: this.checkBox,
        };
        this.$axios
          .post("/w1/order/afterSales/createKey", postData)
          .then((res) => {
            //window.open(this.$root.DownUrl + "/c1/down/" + res.data.data.to_key);
            location.href =
              this.$root.DownUrl + "/c1/down/" + res.data.data.to_key;
          });
      } else {
        this.$message.error(this.$t("Please select data"));
      }
    },
    //获取数据
    getData() {
      const user = window.sessionStorage.getItem("webUser");
      const userArr = JSON.parse(user);
      let query = {
        user_id: userArr.user_info.id,
        page: this.pages.currentPage,
        page_size: 10,
      };
      //获取售后订单列表
      GetAfterGoodsList(query).then((res) => {
        let resData = res.data.data;
        this.pages.currentPage = resData.page;
        this.pages.pageSize = resData.page_size;
        this.pages.total = resData.total;
        this.listData = resData.data;
      });
    },

    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.pages.currentPage = val
      this.getData()
    },
    handleAfterSalesList() {
      this.$router.push({
        path: "/web/orderCenter/applyAfterSalesList",
      });
    },
    //查看详情
    handleAfterSaleOrderDetails(row) {
      //console.log(row)
      // let {href} = this.$router.resolve({path:  "/web/orderCenter/afterSaleOrderDetails",query:{id:row.id}});
      //window.open(href)
      this.$router.push({
        path: "/web/orderCenter/afterSaleOrderDetails",
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.rightSide {
  width: 1120px;
  margin: 15px 0 0 15px;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;

  &_inner {
    //width: 1100px;
    max-height:1000px;
    overflow-y: scroll;
    padding:30px;

    &_title {
      width: 1100px;
      height: 68px;
    }
  }
}

.table_style {
  width: 1100px;
  height: 88px;
  margin-top: 30px;
  border: 1px solid #c0c0cc;
  display: flex;
  align-items: center;
  justify-content: center;

  &_content {
    width: 1192px;
    height: 54px;

    &_first {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &_second {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button_style {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}
</style>
<style lang="scss">
.footer .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #218da0; //修改后的背景图颜色
  color: #fff;
}
</style>
